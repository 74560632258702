import { graphql } from "gatsby"
import * as React from "react"
import Layout from "../components/layout"
import  ContentRenderer  from '../components/contentRenderer'
import Seo from "../components/seo"

const GenericPage = props => {
  //console.log(props)
//console.log("PROPS: ", props.data) 
  return (
    <Layout location={props.path}>
      <Seo meta={props.data.datoCmsPage.seoMetaTags} />
      <ContentRenderer content={props.data.datoCmsPage.contenu}/> 
    </Layout>
  )
}

export const query = graphql`
  query PageQuery($pageIdJim: String) {
    datoCmsPage(originalId: { eq: $pageIdJim }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      titre
      slug
      originalId
      contenu {
        ... on DatoCmsArticle {
            model {
              apiKey
            }
            id
            originalId
            structuredtext {
                value
                blocks {
                  __typename
                      ... on DatoCmsEncartGauche {
                        id: originalId
                        backgroundcolor { hex }
                        textcolor { hex }
                        contenu
                      }
                      ... on DatoCmsVideo {
                        id: originalId
                        video {
                          url
                        }
                        title
                      } 
                      ... on DatoCmsImage {
                        model {
                          apiKey
                        }
                        id: originalId
                        image {
                          gatsbyImageData
                        }
                      } 
                }
              }
        }  
        ... on DatoCmsVideo {
          model {
            apiKey
          }
          id
          originalId
          video {
            url
          }
          title
        } 
        ... on DatoCmsSubnavigation {
          model {
            apiKey
          }
          id
          originalId
          items {
            slug
            id
            titre
          }
        }  

        ... on DatoCmsResumerubriquesection {
          model {
            apiKey
          }
          id
          originalId
          resumerubriques {
            id
            titre
            resume
            lien {
              slug
            }
          }
        }
        
         
        ... on DatoCmsPhraseAccroche {
            model {
              apiKey
            }
            id
            contenu
        }
        ... on DatoCmsImage {
          model {
            apiKey
          }
          id
          image {
            gatsbyImageData
          }
        } 
        ... on DatoCmsDiaporama {
            model {
              apiKey
            }
            pagination
            navigation
            id
           
            slides {
              image { 
                gatsbyImageData (
                  width: 1250
                  height: 461
                  layout: CONSTRAINED
                  imgixParams: {ar: "1250:461", fit: "crop", crop: "focalpoint", duotone:"4E2680,66C4D6"}
                  
                )
              }
              smallImage:image { 
                gatsbyImageData (
                  height: 561
                  width:400
                  layout: CONSTRAINED
                  imgixParams: {ar: "9:16",fit: "crop", crop: "focalpoint", duotone:"4E2680,66C4D6"}
                  
                )
              }
              link { 
                ... on DatoCmsPage {
                  model {
                    apiKey
                  }
                  titre
                  id
                  slug
                }
                ... on DatoCmsDate {
                  model {
                    apiKey
                  }
                  dateDebutShort: dateDebut(locale: "fr", formatString: "ddd DD MMM")
                  dateFinShort: dateFin(locale: "fr", formatString: "ddd DD MMM")
                  titre
                  id
                  slug
                  teaser
                }
                ... on DatoCmsNews {
                  model {
                    apiKey
                  }
                  titre
                  id
                  slug
                }
              }
            }
        }
        ... on DatoCmsEncartGauche {
            model {
              apiKey
            }
            id
            backgroundcolor { hex }
            textcolor { hex }
            contenu
        }
        ... on DatoCmsBouton {
          model {
            apiKey
          }
          id
          lien
          icon
          texte
          lienExterne
      }
     

        ... on DatoCmsBannerfullpage {
            model {
              apiKey
            }
            ...BannerFullPage
        }
        ... on DatoCmsModulenews {
          model {
            apiKey
          }
          id  
        }
        ... on DatoCmsListingCompagnie {
          model {
            apiKey
          }
          id 
          compagnies {  
            ...CompagnieContent
          }
        }
        ... on DatoCmsAgenda {
            model {
              apiKey
            }
            id
            typeAgenda
            format
            nbMaxEvent
        }
        ... on DatoCmsGrid {
            model {
                apiKey
              }
            id
            columns {
              id
              content {
                ... on DatoCmsBouton {
                  model {
                    apiKey
                  }
                  id
                  texte
                  icon
                  lien
                  lienExterne
                }
                ... on DatoCmsDiaporama {
                    model {
                      apiKey
                    }
                    id 
                    pagination
                    navigation
                  
                    slides {
                      image { 
                        gatsbyImageData (
                          width: 1250
                          height: 461
                          layout: FIXED
                          imgixParams: {ar: "1250:461", fit: "crop", crop: "focalpoint"}
                        )
                        
                      }
                      link { 
                        ... on DatoCmsPage {
                          model {
                            apiKey
                          }
                          id
                          slug
                        }
                        ... on DatoCmsDate {
                          model {
                            apiKey
                          }
                          id
                          slug
                        }
                        ... on DatoCmsNews {
                          model {
                            apiKey
                          }
                          id
                          slug
                        }
                      }
                    }
                }
                
                ... on DatoCmsLastNews {
                  model {
                    apiKey
                  }
                  id
                  lastNews { 
                    id
                    slug
                 }
                }

                ... on DatoCmsImage {
                  model {
                    apiKey
                  }
                  id
                  image {
                    gatsbyImageData
                  }
                }

                ... on DatoCmsPhraseAccroche {
                  model {
                    apiKey
                  }
                  id
                  contenu
                }

                ... on DatoCmsBannerfullpage {
                  model {
                    apiKey
                  }
                  ...BannerFullPage
                }

                ... on DatoCmsEncartGauche {
                    id
                    model {
                      apiKey
                    }
                    contenu
                    textcolor { hex }
                    backgroundcolor { hex }
                }
                ... on DatoCmsAccordion {
                  id
                  model {
                    apiKey
                  }
                  title
                  contenumasque { 
                    ...on DatoCmsArticle { ...Article }
                   }
                 
              }


                ... on DatoCmsAgenda {
                    model {
                      apiKey
                    }
                    id
                    typeAgenda
                    format
                    nbMaxEvent
                }

                ... on DatoCmsFormulaireDeContact {
                    model {
                      apiKey
                    }
                    id                   
                }

                ...on DatoCmsBlocAvecCadre {
                  model {
                    apiKey
                  }
                  couleur { hex }
                  id
                  originalId
                  contenuCadre {
                     
                  ... on DatoCmsArticle {
                      model {
                        apiKey
                      }
                      id
                      originalId
                      structuredtext {
                          value 
                          blocks {
                            __typename
                            ... on DatoCmsBouton {
                              id: originalId
                              texte
                              lien
                              icon
                              lienExterne
                            }
                          }                    
                      } 
                    }  
                   


                  }
                }
                ... on DatoCmsArticle {
                    model {
                      apiKey
                    }
                    id
                    originalId
                    structuredtext {
                        value 
                        blocks {
                          __typename
                          ... on DatoCmsBouton {
                            id: originalId
                            texte
                            lien
                            icon
                            lienExterne
                          }
                          ... on DatoCmsImage {
                            id: originalId
                            image {
                              gatsbyImageData (
                                height:200
                              )
                            }
                          }
                          
                        }  
                        links {
                          __typename
                          ... on DatoCmsNews {
                            id: originalId
                            slug
                            titre
                          }
                        }                            
                    } 
                  }  
                }               
              
              width
            }
          }
      }
      
    }
  }
`

export default GenericPage

export const Head = (props) => <title>{props.data.datoCmsPage.titre}</title>